import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  /* Heading, */ Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
  StatLabel,
} from '@chakra-ui/react';
import { AppTemplate } from '../../shared/ui/AppTemplate';
import { sendLeadToVakas } from '../../shared/api/auth';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../shared/enums/routes';
import { Typography } from 'antd';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

function isNumeric(value: string) {
  if (value === '') {
    return true;
  }

  return /^-?\d+$/.test(value);
}

const getQueryParams = () => {
  const search = window.location.search;
  const searchParams = new URLSearchParams(search);
  const keys = Array.from(searchParams.keys());
  return keys.map(x => ({ [`${x}`]: searchParams.get(x) }));
};

export const Home = observer(() => {
  const [error, setError] = useState<boolean>();
  const [errorText, setErrorText] = useState<string | null>(null);
  //* ==================
  const [username, setUserName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  //* ==================
  const [city, setCity] = useState<string>('');
  const queryParams = getQueryParams();

  React.useEffect(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyAODnA4EfNRBeGgA4BNoEb3PYJ5TFcgAlY',
      authDomain: 'missis-laser.firebaseapp.com',
      projectId: 'missis-laser',
      storageBucket: 'missis-laser.appspot.com',
      messagingSenderId: '514174044584',
      appId: '1:514174044584:web:3a7576b39c7a543c6ab59d',
      measurementId: 'G-QTNN569ZLV',
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    logEvent(analytics, 'rozygryshvk');
  }, []);

  const history = useHistory();

  const onSubmit = async () => {
    try {
      await sendLeadToVakas({
        name: username,
        phone: '+7' + phone,
        city,
        utm_source: window.location.hostname,
        ...queryParams,
      }).then(async ({ data }) => {
        history.push(Routes.Success);
      });
    } catch (error) {
      console.log(error);
      history.push(Routes.Error);
    }
  };

  return (
    <AppTemplate
      headerTitle='Тест драйв'
      headerBody={
        <StatLabel style={{ textAlign: 'center', fontSize: 15 }}>
          Если Вы читаете это сообщение, то Вы явно из тех, для кого важно всегда пребывать в ресурсе!
          <br />
          И для этого мы запускаем новую для нашей клиники услугу: IV терапия.
          <br />
          <br />
          Это мультивитаминные персональные комплексы в капельницах, который давно стали неотъемлемой частью жизни
          любого человека, кто всегда стремиться к успеху, красоте и энергии! <br />
          <br />
          И у Вас есть возможность стать одним из первых клиентов, кто попробует этот продукт по самой выгодной цене.
          <br />
          <br />
          Оставьте ваши контактные данные, если Вас интересует данная услуга и вы сможете стать участницей акции. <br />
          Когда произойдет запуск продукта, мы с Вами свяжемся и уточним варианты по записи.
          <br />
          <br />
        </StatLabel>
      }
      //headerBody={`Акция для новых клиентов только в Январе!\nЛазерная эпиляция зон Бикини + Подмышки за 990 руб вместо 2300 руб! Пока есть возможность - оставляйте заявку и приходите на процедуру! Ждём Вас!`}
    >
      <Box
        p={0}
        overflowY='auto'
        flexGrow={1}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Stack spacing='2rem'>
          <Stack spacing='1rem'>
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              Ваши контактные данные
            </Typography.Title>
            <InputGroup>
              <Input
                maxLength={30}
                /* isInvalid={error} */
                placeholder='Введите ваше имя'
                value={username}
                onChange={e => {
                  setUserName(e.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children='+7' />
              <Input
                maxLength={10}
                type='tel'
                /* isInvalid={error} */
                placeholder='Введите ваш телефон'
                value={phone}
                onChange={event => {
                  isNumeric(event.target.value) && setPhone(event?.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup>
              <Select
                value={city}
                onChange={event => {
                  setCity(event?.target.value);
                  setError(false);
                }}
                placeholder='Выберите город'
              >
                <option value='Москва'>Москва</option>
                <option value='Санкт-Петербург'>Санкт-Петербург</option>
                <option value='Казань'>Казань</option>
                <option value='Уфа'>Уфа</option>
                <option value='Тверь'>Тверь</option>
              </Select>
            </InputGroup>
          </Stack>
          {error && (
            <Stack spacing='1rem' style={{ marginTop: 12 }}>
              {errorText && (
                <Alert status='error'>
                  <AlertIcon />
                  {errorText}
                </Alert>
              )}
            </Stack>
          )}

          <Button
            onClick={() => {
              if (city === '' || phone === '' || username === '') {
                setError(true);
                setErrorText(
                  city === ''
                    ? 'Вы не указали город'
                    : phone === ''
                    ? 'Вы не указали свой номер'
                    : username === ''
                    ? 'Вы не указали своё имя'
                    : null
                );
              } else {
                onSubmit();
              }
            }}
            mt='24px'
            w='100%'
            h='60px'
            alignSelf='center'
            colorScheme='teal'
            variant='solid'
          >
            Хочу участвовать
            <br />в акции
          </Button>
        </Stack>
      </Box>
    </AppTemplate>
  );
});
